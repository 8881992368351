import { getInfo } from "@/api/modules/tradeMarksAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        documents: [],
        marks: []
    },

    getters: {
        getDocuments(state) {
            return state.documents.map(d => {
                return {
                    id: d.id,
                    icon: d.pict_url,
                    title: d.name,
                    routerLink: {
                        name: "trade-marks-content",
                        params: { id: d.id }
                    }
                };
            });
        },
        getMarks(state) {
            return state.marks.map(d => {
                return {
                    id: d.id,
                    img: d.pict_url ? d.pict_url : "preview-image.jpg",
                    title: d.name
                };
            });
        }
    },

    mutations: {
        setDocuments(state, documents) {
            if (documents) {
                state.documents = documents;
            }
        },
        setMarks(state, marks) {
            if (marks) {
                state.marks = marks;
            }
        }
    },

    actions: {
        async requestInfo(ctx, document_id) {
            try {
                const info = await getInfo(document_id);

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                if (document_id) {
                    ctx.commit("setMarks", info.data.result);
                } else {
                    ctx.commit("setDocuments", info.data.result);
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
