import { createRouter, createWebHistory } from "vue-router";

import { loadLayoutMiddleware } from "./middleware/loadLayout.middleware";

const routes = [
    {
        path: "/",
        name: "home",
        component: () => import("@/views/HomeView.vue")
    },
    {
        path: "/auth",
        name: "auth",
        component: () => import("../views/AuthView.vue"),
        meta: { layout: "VoidLayout" }
    },
    {
        path: "/active-courses",
        name: "active-courses",
        component: () => import("@/views/ActiveCoursesView.vue")
    },
    {
        path: "/completed-courses",
        name: "completed-courses",
        component: () => import("@/views/CompletedCoursesView.vue")
    },
    {
        path: "/second",
        name: "second",
        component: () => import("../views/SecondView.vue")
    },
    {
        path: "/knowledge-base/:id?",
        name: "knowledge-base",
        component: () => import("../views/PortalSection.vue")
    },
    {
        path: "/userful",
        name: "userful",
        component: () => import("../views/UserfulView.vue")
    },
    {
        path: "/userful/polls",
        name: "polls",
        component: () => import("../views/PollsView.vue")
    },
    {
        path: "/userful/userful",
        name: "userful-child",
        component: () => import("../views/UserfulChildView.vue")
    },
    {
        path: "/userful/contests",
        name: "contests-layout",
        component: () => import("../layouts/ContestsLayout.vue"),
        children: [
            {
                path: "",
                name: "contests",
                component: () => import("../views/ContestsView.vue")
            },
            {
                path: ":id",
                props: true,
                name: "contests-item",
                component: () => import("../views/ContestsItemView.vue")
            },
            {
                path: ":id/:childID",
                props: true,
                name: "contests-item-children",
                component: () => import("../views/ContestsItemChildrenView.vue")
            }
        ]
    },
    {
        path: "/reports",
        name: "reports",
        component: () => import("../views/ReportsView.vue")
    },
    {
        path: "/report-categories/:id/:name",
        name: "report-categories",
        component: () => import("../views/ReportCategoriesView.vue")
    },
    {
        path: "/custom-report/:id/:name",
        name: "custom-report",
        component: () => import("../views/CustomReportView.vue")
    },
    {
        path: "/events",
        name: "events",
        component: () => import("../views/Events.vue")
    },
    {
        path: "/events-calendar",
        name: "events-calendar",
        component: () => import("../views/EventsCalendar.vue")
    },
    {
        path: "/my-requests",
        name: "my-requests",
        component: () => import("../views/MyRequests.vue")
    },
    {
        path: "/events-calendar-by-programs",
        name: "events-calendar-by-programs",
        component: () => import("../views/EventsCalendarByPrograms.vue")
    },
    {
        path: "/education",
        name: "education",
        component: () => import("../views/Education.vue")
    },
    {
        path: "/course/:id",
        name: "course",
        component: () => import("../views/CourseView.vue")
    },
    {
        path: "/completed-course/:id",
        name: "completed-course",
        component: () => import("../views/CompletedCourse.vue")
    },
    {
        path: "/active-course/:id/:openCourse?",
        name: "active-course",
        component: () => import("../views/ActiveCourse.vue")
    },
    {
        path: "/event/:id",
        name: "event",
        component: () => import("../views/Event.vue")
    },
    {
        path: "/event-result/:id",
        name: "event-result",
        component: () => import("../views/Event.vue")
    },
    {
        path: "/education-method/:id",
        name: "education-method",
        component: () => import("../views/EducationMethod.vue")
    },
    {
        path: "/activities",
        name: "activities",
        component: () => import("../views/Activities.vue")
    },
    {
        path: "/archive-activities",
        name: "archive-activities",
        component: () => import("../views/ArchiveActivities.vue")
    },
    {
        path: "/test/:id",
        name: "test",
        component: () => import("../views/Test.vue")
    },
    {
        path: "/active-test/:id",
        name: "active-test",
        component: () => import("../views/ActiveTest.vue")
    },
    {
        path: "/completed-test/:id",
        name: "completed-test",
        component: () => import("../views/CompletedTest.vue")
    },
    {
        path: "/poll/:id",
        name: "poll",
        component: () => import("../views/Poll.vue")
    },
    {
        path: "/mentoring",
        name: "mentoring",
        component: () => import("../views/Mentoring.vue")
    },

    // Последний route для некорректных маршрутов
    {
        path: "/:catchAll(.*)",
        name: "not-found",
        component: () => import("../views/NotFound.vue")
    }
];

const router = createRouter({
    history: createWebHistory("/app/"),
    routes
});

router.beforeEach(loadLayoutMiddleware);

export default router;
