import request from "@/api/index";
import { checkForDocRedirect } from "@/lib/api-middlewares";

/* eslint-disable */
const ENDPOINT =
    "/custom_web_template.html?object_code=rg_store_management_api";
const ACTIONS = {
    getInfo: "get_info"
};

async function getInfo() {
    return checkForDocRedirect(
        await request({
            url: ENDPOINT,
            method: "GET",
            params: {
                action: ACTIONS.getInfo
            }
        })
    );
}

export { getInfo };
