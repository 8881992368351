import STATUS_TYPES from "@/constants/status-types";
import { getDoc } from "@/api/modules/doc";
import ACTION_TYPES from "./action-types";
import MUTATION_TYPES from "./mutation-types";
import { deserialize, deserializeObjects } from "./utils";

export default {
    async [ACTION_TYPES.LOAD_DOC]({ commit }, { docId }) {
        commit(MUTATION_TYPES.SET_LOADING_STATE, {
            code: STATUS_TYPES.PROCESSING,
            error: null
        });

        try {
            const { data } = await getDoc({ docId });

            if (data.error) {
                throw new Error(data.message);
            }

            const { result } = data;

            commit(MUTATION_TYPES.SET_DATA, {
                key: "description",
                value: result.desc
            });
            commit(MUTATION_TYPES.SET_DATA, {
                key: "breadcrumb",
                value: deserialize(result.bread_crumbs)
            });
            commit(MUTATION_TYPES.SET_DATA, {
                key: "children",
                value: deserialize(result.documents)
            });
            commit(MUTATION_TYPES.SET_DATA, {
                key: "files",
                value: deserialize(result.files)
            });
            commit(
                MUTATION_TYPES.SET_OBJECTS,
                deserializeObjects(result.objects)
            );
            commit(MUTATION_TYPES.SET_LOADING_STATE, {
                code: STATUS_TYPES.SUCCESS
            });
        } catch (error) {
            commit(MUTATION_TYPES.SET_LOADING_STATE, {
                code: STATUS_TYPES.ERROR,
                error
            });
        }
    }
};
