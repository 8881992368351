import { checkAccess, getInfo, activateTest } from "@/api/modules/testViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        testInfo: {}
    },

    getters: {
        getTestInfo(state) {
            return state.testInfo;
        }
    },

    mutations: {
        setTestInfo(state, testInfo) {
            state.testInfo = testInfo;
        }
    },

    actions: {
        async requestCheckAccess(ctx, testID) {
            try {
                let response = await checkAccess(testID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                return response.result;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        async requestGetInfo(ctx, testID) {
            try {
                let response = await getInfo(testID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                ctx.commit("setTestInfo", response.result);
            } catch (error) {
                console.log(error);
                window.location.href = `${window.location.origin}/app/not-found`;
            }
        },
        async requestAactivateTest(ctx, testID) {
            try {
                let response = await activateTest(testID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                return response.result;
            } catch (error) {
                console.log(error);
            }
        }
    }
};
