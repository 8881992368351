import { createStore } from "vuex";

import authViewStore from "./modules/authViewStore";
import defaultLayoutStore from "./modules/defaultLayoutStore";
import homeViewStore from "./modules/homeViewStore";
import activeCoursesViewStore from "./modules/activeCoursesViewStore";
import completedCoursesViewStore from "./modules/completedCoursesViewStore";
import reportsViewStore from "./modules/reportsViewStore";
import myRequestsStore from "./modules/myRequestsStore";
import eventsCalendarByProgramsStore from "./modules/eventsCalendarByProgramsStore";
import eventsCalendarStore from "./modules/eventsCalendarStore";
import selfDevelopmentStore from "./modules/selfDevelopmentStore";
import videoFundStore from "./modules/videoFundStore";
import storeManagementStore from "./modules/storeManagementStore";
import serviceStandardsStore from "./modules/serviceStandardsStore";
import tradeMarksStore from "./modules/tradeMarksStore";
import ui from "./modules/ui";
import userful from "./modules/userful/userful.index";
import courseViewStore from "./modules/courseViewStore";
import customReportViewStore from "./modules/customReportViewStore";
import eventStore from "./modules/eventStore";
import eventResultStore from "./modules/eventResultStore";
import completedCourseViewStore from "./modules/completedCourseViewStore";
import activeCourseStore from "./modules/activeCourseStore";
import educationMethodStore from "./modules/educationMethodStore";
import activities from "./modules/activities";
import archiveActivities from "./modules/archiveActivities";
import testViewStore from "./modules/testViewStore";
import pollViewStore from "./modules/pollViewStore";
import portalSectionViewStore from "./modules/portalSectionViewStore";
import activeTestStore from "./modules/activeTestStore";
import eventsStore from "./modules/eventsStore";
import doc from "./modules/doc";
import mentoringStore from "./modules/mentoringStore";

export default createStore({
    modules: {
        ui,
        authViewStore,
        defaultLayoutStore,
        homeViewStore,
        activeCoursesViewStore,
        completedCoursesViewStore,
        reportsViewStore,
        myRequestsStore,
        eventsCalendarByProgramsStore,
        eventsCalendarStore,
        selfDevelopmentStore,
        videoFundStore,
        storeManagementStore,
        serviceStandardsStore,
        tradeMarksStore,
        userful,
        courseViewStore,
        customReportViewStore,
        eventStore,
        eventResultStore,
        completedCourseViewStore,
        activeCourseStore,
        educationMethodStore,
        activities,
        archiveActivities,
        testViewStore,
        pollViewStore,
        portalSectionViewStore,
        activeTestStore,
        eventsStore,
        doc,
        mentoringStore
    }
});
