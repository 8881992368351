import getUserInfo from "@/api/modules/defaultLayoutAPI";

/* eslint-disable */
export default {
    state: () => ({
        userId: 0,
        userFullname: "",
        accessReports: false,
        accessMain: false,
        accessEvents: false,
        accessEdu: false,
        accessKnowledgeBase: false,
        accessUseful: false
    }),
    getters: {
        getUserFullname(state) {
            return state.userFullname;
        }
    },
    mutations: {
        setUserInfo(state, userInfo) {
            if (userInfo.id) state.userId = userInfo.id;
            if (userInfo.is_report) state.accessReports = true;
            if (userInfo.fullname) state.userFullname = userInfo.fullname;

            state.accessMain = userInfo.menu_items.sm_main_stng;
            state.accessEvents = userInfo.menu_items.sm_event_stng;
            state.accessEdu = userInfo.menu_items.sm_educ_stng;
            state.accessKnowledgeBase = userInfo.menu_items.sm_knowbase_stng;
            state.accessUseful = userInfo.menu_items.sm_useful_stng;
        }
    },
    actions: {
        async requestUserInfo(ctx) {
            try {
                const userInfo = await getUserInfo();

                if (userInfo && userInfo.data.error != 0) {
                    throw new Error(userInfo.data.message);
                }

                ctx.commit("setUserInfo", userInfo.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
