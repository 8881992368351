import request from "@/api/index";
import { checkForDocRedirect } from "@/lib/api-middlewares";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_trade_marks_api";
const ACTIONS = {
    getInfo: "get_info"
};

async function getInfo(document_id) {
    return checkForDocRedirect(
        await request({
            url: ENDPOINT,
            method: "POST",
            params: {
                action: ACTIONS.getInfo,
                parameters: JSON.stringify({
                    document_id: document_id
                })
            }
        })
    );
}

export { getInfo };
