import {
    getUsers,
    getMentors,
    setMentor,
    setTraineeStatus,
    getExcel,
    getUserRole
} from "@/api/modules/MentoringAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        info: "",
        user_role: {
            is_lector: false,
            is_store_manager: false
        },
        collaborators: {
            total: 0,
            payload: []
        },
        mentors: {
            payload: []
        }
    },

    getters: {},

    mutations: {
        setCollaborators(state, collaborators) {
            if (collaborators) {
                state.collaborators.total = collaborators.total;
                state.collaborators.payload = collaborators.payload;
            }
        },
        updateCollaborators(state, collaborators) {
            if (collaborators) {
                state.collaborators.payload.push(...collaborators.payload);
            }
        },
        setMentors(state, mentors) {
            if (mentors) {
                state.mentors.total = mentors.total;
                state.mentors.payload = mentors.payload;
            }
        },
        setError(state, info) {
            state.info = info;
        },
        changeMentor(state, data) {
            if (data.mentor_fullname) {
                state.collaborators.payload = state.collaborators.payload.map((elem) => {
                    if (elem.id == data.collaborator_id) {
                        elem.fullaname = elem.fullname;
                        elem.id = elem.id;
                        elem.mentor_id = data.mentor_id;
                        elem.mentor_fullname = data.mentor_fullname;
                    }
                    return elem;
                })
            }
        },
        setUserRole(state, data) {
            if (data) {
                state.user_role = data;
            }
        }
    },

    actions: {
        async requestGetUsers(ctx, payload) {
            try {
                const collaborators = await getUsers(payload);

                if (collaborators && collaborators.data.error !== 0) {
                    ctx.commit('setError', 'Нет доступа');
                }
                ctx.commit('setCollaborators', collaborators.data.result)
            } catch (err) {
                console.log(err);
            }
        },

        async requestGetUsersUpdate(ctx, payload) {
            try {
                const collaborators = await getUsers(payload);

                if (collaborators && collaborators.data.error !== 0) {
                    ctx.commit('setError', 'Нет доступа');
                }
                ctx.commit('updateCollaborators', collaborators.data.result)
            } catch (err) {
                console.log(err);
            }
        },

        async requestGetMentors(ctx, payload) {
            try {
                const mentors = await getMentors(payload);

                if (mentors && mentors.data.error !== 0) {
                    throw new Error(mentors.data.message)
                }
                ctx.commit('setMentors', mentors.data.result)
            } catch (err) {
                console.log(err);
            }
        },

        async requestSetMentor(ctx, payload) {
            await setMentor({
                collaborator_id: payload.collaborator_id,
                mentor_id: payload.mentor_id
            }).catch(error => console.log(error));

            ctx.commit('changeMentor', payload);
        },

        async requestSetTraineeStatus(ctx, payload) {
            await setTraineeStatus(payload).catch(error => console.log(error));
        },

        async requestGetExcel(ctx) {
            await getExcel().catch(error => console.log(error));
        },

        async requestGetUserRole(ctx, payload) {
            try {
                const user_role = await getUserRole(payload);
                if(user_role.data.error !== 0){
                    throw new Error(user_role.data.message)
                }
                ctx.commit('setUserRole', user_role.data.result);
            }
            catch (err) {
                console.log(err);
            }
        }
    }
};

