import request from "@/api/index";
import { checkForDocRedirect } from "@/lib/api-middlewares";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_tutorial_api";
const ACTIONS = {
    getInfo: "get_info",
    getLibraryMaterials: "get_library_materials",
    getDocumentObjects: "get_document_objects"
};

async function getInfo() {
    return checkForDocRedirect(
        await request({
            url: ENDPOINT,
            method: "GET",
            params: {
                action: ACTIONS.getInfo
            }
        })
    );
}

async function getLibraryMaterials(document_id) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getLibraryMaterials,
            parameters: JSON.stringify({
                document_id: document_id
            })
        }
    });
}

async function getDocumentObjects(document_id = "") {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getDocumentObjects,
            parameters: JSON.stringify({
                document_id: document_id
            })
        }
    });
}

export { getInfo, getLibraryMaterials, getDocumentObjects };
