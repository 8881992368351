import { getInfo } from "@/api/modules/storeManagementAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        courses: [],
        objects: {},
        objectsMap: {},
        itemsNameFilter: ""
    },

    getters: {
        getCourses(state) {
            return state.courses.map(c => {
                return {
                    id: c.id,
                    img: c.pict_url ? c.pict_url : "preview-image.jpg",
                    title: c.name,
                    routerLink: {
                        name: "course",
                        params: {
                            id: c.id
                        }
                    }
                };
            });
        },
        getObjects(state) {
            if (state.itemsNameFilter) {
                for (let objectType in state.objects) {
                    const currentObjectItems = state.objects[objectType];
                    const filteredItems = currentObjectItems.filter(item => {
                        return item?.name.includes(state.itemsNameFilter);
                    });
                    console.log(filteredItems);
                    console.log(objectType);
                    state.objects[objectType] = filteredItems;
                    console.log(state.objects[objectType]);
                }
            }

            return state.objects;
        }
    },

    mutations: {
        setCourses(state, courses) {
            if (courses) {
                state.courses = courses;
            }
        },
        setObjects(state, objects) {
            if (objects) {
                const normalizedObjects = {};

                for (let objectType in objects) {
                    const currentObjectItems = objects[objectType];
                    const normalizedItems = currentObjectItems.map(item => {
                        let currentRouterLink = {};

                        if (objectType === "assessment") {
                            currentRouterLink = {
                                name: "test",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "course") {
                            currentRouterLink = {
                                name: "course",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "custom_report") {
                            currentRouterLink = {
                                name: "custom-report",
                                params: {
                                    id: item?.id,
                                    name: item?.name
                                }
                            };
                        } else if (objectType === "education_method") {
                            currentRouterLink = {
                                name: "education-method",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "event") {
                            currentRouterLink = {
                                name: "event",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "poll") {
                            currentRouterLink = {
                                name: "poll",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else {
                            currentRouterLink.url = `${window.location.origin}/_wt/${item?.id}`;
                        }

                        if (currentRouterLink?.url) {
                            return {
                                id: item.id,
                                img: item.pict_url
                                    ? item.pict_url
                                    : "preview-image.jpg",
                                title: item.name,
                                url: item.url
                            };
                        }

                        return {
                            id: item.id,
                            img: item.pict_url
                                ? item.pict_url
                                : "preview-image.jpg",
                            title: item.name,
                            routerLink: item
                        };
                    });

                    normalizedObjects[objectType] = normalizedItems;
                }

                state.objects = normalizedObjects;

                for (let objectType in state.objects) {
                    if (objectType === "assessment") {
                        state.objectsMap[objectType] = "Тест";
                        continue;
                    }
                    if (objectType === "compound_program") {
                        state.objectsMap["compound_program"] =
                            "Модульная программа";
                        continue;
                    }
                    if (objectType === "course") {
                        state.objectsMap[objectType] = "Электронный курс";
                        continue;
                    }
                    if (objectType === "custom_report") {
                        state.objectsMap[objectType] = "Настраиваемый отчет";
                        continue;
                    }
                    if (objectType === "education_method") {
                        state.objectsMap[objectType] = "Учебная программа";
                        continue;
                    }
                    if (objectType === "education_program") {
                        state.objectsMap[objectType] = "Набор программ";
                        continue;
                    }
                    if (objectType === "event") {
                        state.objectsMap[objectType] = "Мероприятие";
                        continue;
                    }
                    if (objectType === "library_material") {
                        state.objectsMap[objectType] = "Материал библиотеки";
                        continue;
                    }
                    if (objectType === "poll") {
                        state.objectsMap[objectType] = "Опрос";
                        continue;
                    }
                    if (objectType === "resource") {
                        state.objectsMap[objectType] = "Ресурс базы";
                        continue;
                    }
                }
            }
        },
        setItemsNameFilter(state, filterValue) {
            state.itemsNameFilter = filterValue;
        }
    },

    actions: {
        async requestInfo(ctx) {
            try {
                const info = await getInfo();

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setCourses", info.data.result.courses);
                ctx.commit("setObjects", info.data.result?.objects);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
