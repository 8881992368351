import request from "@/api/index";
import { checkForDocRedirect } from "@/lib/api-middlewares";

const ENDPOINT = "/custom_web_template.html?object_code=rg_trade_marks_api";
const ACTIONS = {
    GET_INFO: "get_info"
};

// eslint-disable-next-line import/prefer-default-export
export const getDoc = ({ docId }) =>
    request({
        url: ENDPOINT,
        method: "GET",
        params: {
            action: ACTIONS.GET_INFO,
            parameters: JSON.stringify({
                document_id: docId
            })
        }
    });
