import { routeName } from "vue-router";

import { getInfo } from "@/api/modules/portalSectionViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        breadCrumbs: [],
        title: "",
        description: "",
        videos: [],
        sections: [],
        objects: {},
        objectsMap: {},
        objectsDisabled: true,
        files: [],
        nameFilterValue: ""
    },

    getters: {
        getBreadCrumbs(state) {
            return state.breadCrumbs;
        },
        getTitle(state) {
            return state.title;
        },
        getDescription(state) {
            return state.description;
        },
        getSections(state) {
            return state.sections;
        },
        getObjects(state) {
            let filteredObjects = state.objects;

            if (state.nameFilterValue) {
                filteredObjects = {};
                for (let objectType in state.objects) {
                    const currentObjectItems = state.objects[objectType];
                    filteredObjects[objectType] = currentObjectItems.filter(
                        object => {
                            return object?.title
                                ?.trim()
                                ?.toLowerCase()
                                .includes(
                                    state.nameFilterValue.trim().toLowerCase()
                                );
                        }
                    );
                }
            }

            return filteredObjects;
        },
        getObjectsMap(state) {
            return state.objectsMap;
        },
        getObjectsDisabled(state) {
            return state.objectsDisabled;
        },
        getFiles(state) {
            return state.files;
        }
    },

    mutations: {
        resetBreadCrumbs(state) {
            state.breadCrumbs = [];
        },
        resetPageData(state) {
            state.breadCrumbs = [];
            state.description = "";
            state.title = "";
            state.videos = [];
            state.sections = [];
            state.objects = {};
            state.objectsMap = {};
            state.objectsDisabled = true;
            state.files = [];
            state.nameFilterValue = "";
        },
        setPageData(state, pageData) {
            if (pageData?.bread_crumbs) {
                state.breadCrumbs.push({ name: "Главная", routeName: "home" });

                for (let i = 0; i < pageData.bread_crumbs?.length; i += 1) {
                    const currentBreadCrumb = pageData.bread_crumbs[i];

                    if (i + 1 === pageData.bread_crumbs.length) {
                        state.title = currentBreadCrumb?.name || "";
                    }

                    state.breadCrumbs.push({
                        name: currentBreadCrumb?.name || "",
                        routeLink: {
                            name: routeName,
                            params: { id: currentBreadCrumb?.id || "" }
                        }
                    });
                }
            }

            if (pageData?.desc) {
                state.description = pageData.desc;
            }

            if (pageData?.videos?.length) {
                state.videos = pageData.videos;
            } else if (pageData?.documents) {
                state.sections = pageData.documents;
            }

            if (pageData?.objects) {
                const normalizedObjects = {};

                if (state.videos?.length) {
                    pageData.objects.videos = state.videos;
                }

                for (let objectType in pageData.objects) {
                    const currentObjectItems = pageData.objects[objectType];
                    const normalizedItems = currentObjectItems.map(item => {
                        state.objectsDisabled = false;
                        let currentRouterLink = {};

                        if (objectType === "assessment") {
                            currentRouterLink = {
                                name: "test",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "course") {
                            currentRouterLink = {
                                name: "course",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "custom_report") {
                            currentRouterLink = {
                                name: "custom-report",
                                params: {
                                    id: item?.id,
                                    name: item?.name
                                }
                            };
                        } else if (objectType === "education_method") {
                            currentRouterLink = {
                                name: "education-method",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "event") {
                            currentRouterLink = {
                                name: "event",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "poll") {
                            currentRouterLink = {
                                name: "poll",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "videos") {
                            currentRouterLink.url = item?.link_href;
                        } else {
                            currentRouterLink.url = `${window.location.origin}/_wt/${item?.id}`;
                        }

                        if (currentRouterLink?.url) {
                            return {
                                id: item.id,
                                img: item.pict_url ? item.pict_url : "",
                                title: item.name,
                                url: currentRouterLink.url
                            };
                        }

                        return {
                            id: item.id,
                            img: item.pict_url ? item.pict_url : "",
                            title: item.name,
                            routerLink: currentRouterLink
                        };
                    });

                    normalizedObjects[objectType] = normalizedItems;
                }

                state.objects = normalizedObjects;

                for (let objectType in state.objects) {
                    if (objectType === "assessment") {
                        state.objectsMap[objectType] = "Тесты";
                        continue;
                    }
                    if (objectType === "compound_program") {
                        state.objectsMap["compound_program"] =
                            "Модульная программа";
                        continue;
                    }
                    if (objectType === "course") {
                        state.objectsMap[objectType] = "Электронные курсы";
                        continue;
                    }
                    if (objectType === "custom_report") {
                        state.objectsMap[objectType] = "Настраиваемые отчеты";
                        continue;
                    }
                    if (objectType === "education_method") {
                        state.objectsMap[objectType] = "Учебные программы";
                        continue;
                    }
                    if (objectType === "education_program") {
                        state.objectsMap[objectType] = "Наборы программ";
                        continue;
                    }
                    if (objectType === "event") {
                        state.objectsMap[objectType] = "Мероприятия";
                        continue;
                    }
                    if (objectType === "library_material") {
                        state.objectsMap[objectType] = "Материалы библиотеки";
                        continue;
                    }
                    if (objectType === "poll") {
                        state.objectsMap[objectType] = "Опросы";
                        continue;
                    }
                    if (objectType === "resource") {
                        state.objectsMap[objectType] = "Ресурсы базы";
                        continue;
                    }
                    if (objectType === "videos") {
                        state.objectsMap[objectType] = "Видео";
                        continue;
                    }
                }
            }

            if (pageData?.files) {
                state.files = pageData.files;
            }
        },
        setNameFilterValue(state, filterValue) {
            state.nameFilterValue = filterValue;
        }
    },

    actions: {
        async requestGetInfo(ctx, sectionID) {
            try {
                let response = await getInfo(sectionID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                ctx.commit("setPageData", response.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
