import { getInfo } from "@/api/modules/eventsAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        info: {}
    },

    getters: {},

    mutations: {
        setInfo(state, info) {
            if (info) {
                state.info = info;
            }
        }
    },

    actions: {
        async requestInfo(ctx) {
            try {
                const info = await getInfo();

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setInfo", info.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
