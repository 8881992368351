import getHomeViewData from "@/api/modules/homeViewAPI";

function validateDate(date) {
    const arrDate = date.split(" ");
    return new Date(
        `${arrDate[0].split(".").reverse().join("-")}T${arrDate[1]}`
    );
}

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        news: [],
        events: { data: [], sort: "" },
        courses: { data: [], sort: "" },
        taskTableViewMode: "active",
        sidebarOpen: false,
        sidebarOpenClass: "",
        inputActiveState: false
    },
    getters: {
        getNews(state) {
            return state.news;
        },
        getEvents(state) {
            if (!state.events.data.length) return [];
            return state.events.data.slice(0, 2);
        },
        getEventsSortMod(state) {
            if (!state.events.data.length) return "";
            return state.events.sort;
        },
        getActiveCourses(state) {
            if (state.courses.data?.active?.length) {
                return state.courses.data.active;
            }
            return [];
        },
        getDoneCourses(state) {
            if (state.courses.data?.done?.length) {
                return state.courses.data.done;
            }
            return [];
        },
        getNumActiveCourses(state) {
            if (state.courses.data?.count) {
                return (
                    state.courses.data.count.all - state.courses.data.count.done
                );
            }
            return 0;
        },
        getNumDoneCourses(state) {
            if (state.courses.data?.count) {
                return state.courses.data.count.done;
            }
            return 0;
        },
        getTaskTableViewMode(state) {
            return state.taskTableViewMode;
        },
        getSidebarState(state) {
            return state.sidebarOpen;
        },
        getSidebarOpenClass(state) {
            return state.sidebarOpenClass;
        },
        getInputActiveState(state) {
            return state.inputActiveState;
        }
    },
    mutations: {
        setHomeViewData(state, homeViewData) {
            if (homeViewData.news) state.news = homeViewData.news;
            if (homeViewData.events)
                state.events = { data: homeViewData.events, sort: "" };
            if (homeViewData.tasks)
                state.courses = { data: homeViewData.tasks, sort: "" };
        },
        filterEventByName(state) {
            if (state.events?.data.length) {
                if (state.events.sort === "name") {
                    state.events.data.reverse();
                    state.events.sort = "nameReverse";
                } else {
                    state.events.data.sort((a, b) => {
                        const nameA = a.name.toLowerCase(),
                            nameB = b.name.toLowerCase();
                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    });
                    state.events.sort = "name";
                }
            }
        },
        filterEventByType(state) {
            if (state.events.data.length) {
                if (state.events.sort === "type") {
                    state.events.data.reverse();
                    state.events.sort = "typeReverse";
                } else {
                    state.events.data.sort((a, b) => {
                        const typeA = a.type.toLowerCase(),
                            typeB = b.type.toLowerCase();
                        if (typeA < typeB) return -1;
                        if (typeA > typeB) return 1;
                        return 0;
                    });
                    state.events.sort = "type";
                }
            }
        },
        filterEventByDateStart(state) {
            if (state.events.data.length) {
                if (state.events.sort === "dateStart") {
                    state.events.data.reverse();
                    state.events.sort = "dateStartReverse";
                } else {
                    state.events.data.sort((a, b) => {
                        const dateStartA = validateDate(a.start_date).valueOf(),
                            dateStartB = validateDate(b.start_date).valueOf();
                        return dateStartA - dateStartB;
                    });
                    state.events.sort = "dateStart";
                }
            }
        },
        filterEventByDateEnd(state) {
            if (state.events.data.length) {
                if (state.events.sort === "dateEnd") {
                    state.events.data.reverse();
                    state.events.sort = "dateEndReverse";
                } else {
                    state.events.data.sort((a, b) => {
                        const dateEndA = validateDate(a.finish_date).valueOf(),
                            dateEndB = validateDate(b.finish_date).valueOf();
                        return dateEndA - dateEndB;
                    });
                    state.events.sort = "dateEnd";
                }
            }
        },
        changeViewModeForTaskTable(state, viewMode) {
            if (viewMode) {
                state.taskTableViewMode = viewMode;
            }
        },
        changeSidebarOpenClass(state) {
            state.sidebarOpen = !state.sidebarOpen;
            if (state.sidebarOpen) {
                state.sidebarOpenClass = "layout-wrapper__sidebar--open";
            } else {
                state.sidebarOpenClass = "";
            }
        },
        changeinputActiveState(state, open) {
            if (open) {
                state.inputActiveState = true;
            } else {
                state.inputActiveState = false;
            }
        }
    },
    actions: {
        async requestHomePageData(ctx) {
            try {
                const homeViewData = await getHomeViewData();

                if (homeViewData && homeViewData.data.error != 0) {
                    throw new Error(homeViewData.data.message);
                }

                console.log(homeViewData.data.result);
                ctx.commit("setHomeViewData", homeViewData.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
