import request from "@/api/index";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_my_event_api";
const ACTIONS = {
    getInfo: "get_info"
};

async function getInfo(page, status_id, start_date, finish_date) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                page: page,
                status_id: status_id,
                start_date: start_date,
                finish_date: finish_date
            })
        }
    });
}

export { getInfo };
