import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_active_learning_api";
const ACTIONS = {
    getInfo: "get_info"
};

export default async ({ status, search, page }) => {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                status,
                search,
                page
            })
        }
    });
};
