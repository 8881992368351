import request from "@/api/index";

const ENDPOINTS = {
    rgCustomReportAPI:
        "/custom_web_template.html?object_code=rg_custom_report_api",
    WTCustumReportsAPI: "/pp/Ext5/extjs_json_collection_data.html",
    downloadreportAPI: "/assessment_excel_export.html"
};

const ACTIONS = {
    getFilters: "get_filters"
};

async function getFilters(reportID) {
    const bodyFormData = new FormData();
    bodyFormData.append("action", ACTIONS.getFilters);
    bodyFormData.append("report_id", reportID);

    return request({
        url: ENDPOINTS.rgCustomReportAPI,
        method: "POST",
        data: bodyFormData
    });
}

async function getCustomReportData({
    encodeParameters,
    id,
    context,
    params,
    pagination
}) {
    const bodyFormData = new FormData();
    bodyFormData.append("collection_code", "CUSTOM_REPORT_DATA");

    if (encodeParameters) {
        console.log(encodeParameters);
        bodyFormData.append("parameters", encodeParameters);
    } else {
        bodyFormData.append(
            "parameters",
            `sReportMode=table;custom_report_id=${id};context=${encodeURIComponent(
                JSON.stringify(context)
            )};parameters_list=${params};`
        );
    }

    bodyFormData.append("start", pagination?.start || "0");
    bodyFormData.append("limit", pagination?.limit || "10");
    // bodyFormData.append(
    //     "sort",
    //     JSON.stringify([
    //         {
    //             property: "",
    //             direction: ""
    //         }
    //     ])
    // );

    return request({
        url: ENDPOINTS.WTCustumReportsAPI,
        method: "POST",
        data: bodyFormData
    });
}

async function downloadReport(reportID, PDFMode) {
    const parameters = {
        mode: "custom_report_data",
        custom_report_id: reportID
    };

    if (PDFMode) {
        parameters.header = "pdf";
        parameters.ext = "pdf";
        parameters.cdt = "attachment";
    }

    return request({
        url: ENDPOINTS.downloadreportAPI,
        method: "GET",
        responseType: "application/vnd.ms-excel",
        params: parameters
    });
}

export { getFilters, getCustomReportData, downloadReport };
