import request from "@/api/index";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_events_api";
const ACTIONS = {
    getInfo: "get_info"
};

async function getInfo() {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo
        }
    });
}

export { getInfo };
