import STATUS_TYPES from "@/constants/status-types";

export default () => ({
    title: "",
    description: "",
    objects: {},
    files: [],
    children: [],
    breadcrumb: [],
    status: {
        loading: {
            error: null,
            code: STATUS_TYPES.INIT
        }
    }
});
