import {
    getInfo,
    addPersonToEvent,
    changeStatus,
    changeComment,
    deletePersonToEvent,
    changeEventStatus,
    changePatricipantStatus
} from "@/api/modules/eventAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        info: {}
    },

    getters: {},

    mutations: {
        setInfo(state, info) {
            if (info) {
                state.info = info;
            }
        }
    },

    actions: {
        async requestInfo(ctx, event_id) {
            try {
                const info = await getInfo(event_id);

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setInfo", info.data.result);
            } catch (error) {
                console.log(error);
            }
        },

        async requestAddPersonToEvent(ctx, event_id) {
            await addPersonToEvent(event_id).catch(error => console.log(error));
        },

        async requestChangeStatus(ctx, payload) {
            await changeStatus(payload.event_id, payload.value).catch(error =>
                console.log(error)
            );
        },

        async requestChangeComment(ctx, payload) {
            await changeComment(payload.event_id, payload.comment).catch(
                error => console.log(error)
            );
        },

        async requestDeletePersonToEvent(ctx, event_id) {
            await deletePersonToEvent(event_id).catch(error => console.log(error));
        },

        async requestChangeEventStatus(ctx, payload) {
            await changeEventStatus(payload.event_id, payload.status).catch(error => console.log(error));
        },

        async requestChangePatricipantStatus(ctx, payload) {
            await changePatricipantStatus(payload.event_id, payload.person_id, payload.field, payload.value).catch(error => console.log(error));
        }
    }
};
