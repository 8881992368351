export const checkForDocRedirect = response => {
    if (response.data?.result?.display_standard) {
        window.history.replaceState(
            window.history.state,
            "",
            `${window.location.origin}/_wt/${
                response.data?.result.document_id || response.data?.result.id
            }`
        );

        if (response.data?.result?.url) {
            window.location.href = `/${response.data.result.url}`;
        } else {
            window.location.href = `/_wt/${
                response.data.result.document_id || response.data.result.id
            }`;
        }
    }

    return response;
};
