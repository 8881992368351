import { getPollsViewData } from "@/api/modules/userfulViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        polls: [],
        description: ""
    },

    getters: {
        getPolls(state) {
            return state.polls;
        },
        getDescription(state) {
            return state.description;
        }
    },

    mutations: {
        setPollsViewData(state, pollsViewData) {
            if (pollsViewData) {
                let normalizePolls = [];
                for (let i = 0; i < pollsViewData.length; i++) {
                    const currentPoll = pollsViewData[i];
                    const normalizePoll = {
                        url: currentPoll.link_href || "",
                        title: currentPoll.name || "",
                        icon: currentPoll.pict_url || ""
                    };
                    normalizePolls.push(normalizePoll);
                    if (currentPoll.desc) {
                        state.description = currentPoll.desc;
                    }
                }
                state.polls = normalizePolls;
            }
        }
    },

    actions: {
        async requestPollsPageData(ctx) {
            try {
                const pollsViewData = await getPollsViewData();

                if (pollsViewData && pollsViewData.data.error != 0) {
                    throw new Error(pollsViewData.data.message);
                }

                ctx.commit("setPollsViewData", pollsViewData.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
