import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_header_api";
const ACTIONS = {
    getInfo: "get_info"
};

export default async () => {
    return request({
        url: ENDPOINT,
        method: "GET",
        params: {
            action: ACTIONS.getInfo
        }
    });
};
