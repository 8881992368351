import { getInfo } from "@/api/modules/eventsCalendarAPI";

function parseDate(date) {
    const arrDate = date.split(" ");
    return new Date(
        `${arrDate[0].split(".").reverse().join("-")}T${arrDate[1]}`
    );
}

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        events: [],
        stockEvents: []
    },

    getters: {
        getEvents(state) {
            return state.events;
        },
        getStockEvents(state) {
            return state.stockEvents;
        }
    },

    mutations: {
        setEvents(state, events) {
            if (events) {
                state.stockEvents = events;

                state.events = [];
                events.forEach(e => {
                    let date;
                    let index_date = parseDate(e.start_date);
                    let finish_date = parseDate(e.finish_date);

                    while (index_date <= finish_date) {
                        date = index_date
                            .toLocaleDateString()
                            .split(".");

                        state.events.push({
                            id: e.id,
                            code: e.code,
                            title: e.name,
                            start_date: e.start_date,
                            finish_date: e.finish_date,
                            status: e.status,
                            type: e.type,
                            date: `${Number(date[2])}-${date[1]}-${date[0]}`,
                            day: Number(date[0]),
                            month: Number(date[1]),
                            year: Number(date[2]),
                            url: "/app/event/" + e.id
                        });

                        index_date.setDate(index_date.getDate() + 1);
                    }
                });
            }
        }
    },

    actions: {
        async requestInfo(ctx, params) {
            try {
                const info = await getInfo(
                    params.page,
                    params.status_id,
                    params.start_date,
                    params.finish_date
                );

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setEvents", info.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
