import request from "@/api/index";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_mentoring_api";
const ACTIONS = {
    getUsers: "get_users",
    getMentors: "get_mentors",
    setMentor: "set_mentor",
    setTraineeStatus: "set_trainee_status",
    getUserRole: "get_user_role"
};

async function getUsers(data) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getUsers,
            parameters: JSON.stringify(data)
        }
    });
}

async function getMentors(data) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getMentors,
            parameters: JSON.stringify(data)
        }
    });
}

async function setMentor(data) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.setMentor,
            parameters: JSON.stringify(data)
        }
    });
}

async function setTraineeStatus(data) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.setTraineeStatus,
            parameters: JSON.stringify(data)
        }
    });
}

async function getExcel() {
    return request({
        url: "/custom_web_template.html?object_code=rg_mentoring_excel",
        method: "GET",
        params: {
            "test": "1"
        }
    });
}

async function getUserRole() {
    return request({
        url: ENDPOINT,
        method: 'POST',
        params: {
            action: ACTIONS.getUserRole
        }
    })
}

export { getUsers, getMentors, setMentor, setTraineeStatus, getExcel, getUserRole }