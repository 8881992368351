import { getInfo } from "@/api/modules/completedCourseViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        courseInfo: {},
        completionRate: 0,
        modulesRate: {
            total: 0,
            completed: 0
        },
        courseMap: [],
        nestingMap: {}
    },

    getters: {
        getCourseInfo(state) {
            return state.courseInfo;
        },
        getPercentage(state) {
            return state.completionRate;
        }
    },

    mutations: {
        resetPageData(state) {
            state.courseInfo = {};
            state.completionRate = 0;
            state.courseMap = [];
            state.nestingMap = {};
        },
        setCourseInfo(state, courseInfo) {
            if (!state.courseInfo) return;
            state.courseInfo = courseInfo;
            state.completionRate =
                (state.courseInfo?.modules?.completed /
                    state.courseInfo?.modules?.max) *
                    100 || 0;
        },
        normalizeCourseMap(state) {
            if (!state.courseInfo?.course_map?.length) return;
            const courseMap = state.courseInfo.course_map;

            let nestingLevel = 0;
            for (let i = 0; i < courseMap.length; i += 1) {
                currentCoursePart = courseMap[i];

                // nesting level change
                if (currentCoursePart.parent_part_code) {
                    nestingLevel =
                        state.nestingMap[currentCoursePart.parent_part_code] +
                        1;
                } else {
                    nestingLevel = 0;
                }

                if (currentCoursePart.type === "folder") {
                    state.nestingMap[currentCoursePart.code] = nestingLevel;
                }

                currentCoursePart.level = nestingLevel;

                // view status change
                if (currentCoursePart.level > 0) {
                    currentCoursePart.active = false;
                } else {
                    currentCoursePart.active = true;
                }
            }
        },
        expandFolderContents(folderCode) {}
    },

    actions: {
        async requestGetInfo(ctx, courseID) {
            try {
                let response = await getInfo(courseID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                ctx.commit("setCourseInfo", response.result);
            } catch (error) {
                console.log(error);
                window.location.href = `${window.location.origin}/app/not-found`;
            }
        }
    }
};
