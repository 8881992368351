import getActiveCoursesViewData from "@/api/modules/activeCoursesViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        activeCourses: [],
        maxPage: 1
    },

    getters: {
        getActiveCourses(state) {
            return state.activeCourses;
        },
        getMaxPage(state) {
            return state.maxPage;
        }
    },

    mutations: {
        setActiveCoursesViewData(state, activeCoursesViewData) {
            if (activeCoursesViewData) {
                state.activeCourses = activeCoursesViewData;
                if (activeCoursesViewData[0]?.pages) {
                    state.maxPage = activeCoursesViewData[0].pages;
                }
            }
        }
    },

    actions: {
        async requestActiveCoursesPageData(
            ctx,
            options = { status: "", search: "", page: 0 }
        ) {
            try {
                const activeCoursesViewData = await getActiveCoursesViewData(
                    options
                );

                if (
                    activeCoursesViewData &&
                    activeCoursesViewData.data.error != 0
                ) {
                    throw new Error(activeCoursesViewData.data.message);
                }

                console.log(activeCoursesViewData.data.result);
                ctx.commit(
                    "setActiveCoursesViewData",
                    activeCoursesViewData.data.result
                );
            } catch (error) {
                console.log(error);
            }
        }
    }
};
