import request from "@/api/index";

const API_ENDPOINT =
    "/custom_web_template.html?object_code=rg_active_test_learning_api";
const ARCHIVE_API_ENDPOINT =
    "/custom_web_template.html?object_code=rg_test_learning_api";
const ACTIONS = {
    getInfo: "get_info"
};

export const getActivities = ({ isArchive, status, search, page } = {}) => {
    return request({
        url: isArchive ? ARCHIVE_API_ENDPOINT : API_ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                status,
                search,
                page
            })
        }
    });
};
