<template>
    <app-layout>
        <router-view></router-view>
    </app-layout>
</template>

<script>
import { mapActions } from "vuex";

import AppLayout from "@/layouts/AppLayout.vue";
import { INIT } from "./store/modules/ui/action_types";

export default {
    components: {
        AppLayout
    },
    async created() {
        this.initUiModule();
    },
    methods: {
        ...mapActions({
            initUiModule: `ui/${INIT}`
        })
    }
};
</script>

<style lang="scss"></style>
