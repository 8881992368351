import {
    getReportsViewData,
    getReportFile
} from "@/api/modules/reportsViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        documents: [],
        files: [],
        reports: [],
        documentNameFilter: "",
        reportNameFilter: "",
        description: ""
    },

    getters: {
        getDocuments(state) {
            if (state.documentNameFilter) {
                return state.documents.filter(document => {
                    return document.name
                        .toLowerCase()
                        .includes(state.documentNameFilter.toLowerCase());
                });
            }
            return state.documents;
        },
        getFiles(state) {
            return state.files;
        },
        getReports(state) {
            if (state.reportNameFilter) {
                return state.reports.filter(report => {
                    return report.name
                        .toLowerCase()
                        .includes(state.reportNameFilter);
                });
            }
            return state.reports;
        },
        getCustomReportFilters(state) {
            return "";
        },
        getDescription(state) {
            return state.description;
        }
    },

    mutations: {
        setReportsViewData(state, reportsViewData) {
            if (reportsViewData) {
                state.documents = reportsViewData.documents;
                state.files = reportsViewData.files;
                state.reports = reportsViewData.reports;
                state.description = reportsViewData.desc;
            }
        },
        changeDocumentNameFilter(state, filterValue) {
            state.documentNameFilter = filterValue;
        },
        changeReportNameFilter(state, filterValue) {
            state.reportNameFilter = filterValue;
        },
        resetReportsData(state) {
            state.documents = [];
            state.files = [];
            state.reports = [];
            state.description = "";
            state.documentNameFilter = "";
            state.reportNameFilter = "";
        }
    },

    actions: {
        async requestReportsPageData(ctx, docId = "") {
            try {
                const reportsViewData = await getReportsViewData(docId);

                if (reportsViewData && reportsViewData.data.error != 0) {
                    throw new Error(reportsViewData.data.message);
                }

                ctx.commit("setReportsViewData", reportsViewData.data.result);
            } catch (error) {
                console.log(error);
            }
        },
        async requestCustomReportData(ctx, {}) {},
        async downloadReportFile(ctx, { name, url }) {
            getReportFile(url)
                .then(response => {
                    const url = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", name);
                    link.click();
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
};
