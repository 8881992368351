import {
    getInfo,
    finishAttempt,
    finishTest
} from "@/api/modules/activeTestViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        testInfo: {}
    },

    getters: {
        getTestInfo(state) {
            return state.testInfo;
        }
    },

    mutations: {
        setTestInfo(state, testInfo) {
            if (!state.testInfo) return;
            state.testInfo = testInfo;
        }
    },

    actions: {
        async requestGetInfo(ctx, testID) {
            try {
                let response = await getInfo(testID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                ctx.commit("setTestInfo", response.result);
            } catch (error) {
                console.log(error);
                // window.location.href = `${window.location.origin}/app/not-found`;
            }
        },
        async requestFinishAttempt(ctx, testID) {
            try {
                let response = await finishAttempt(testID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                return response.result;
            } catch (error) {
                console.log(error);
                // window.location.href = `${window.location.origin}/app/not-found`;
            }
        },
        async requestFinishTest(ctx, testID) {
            try {
                let response = await finishTest(testID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                return response.result;
            } catch (error) {
                console.log(error);
                // window.location.href = `${window.location.origin}/app/not-found`;
            }
        }
    }
};
