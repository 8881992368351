import {
    getFilters,
    getCustomReportData,
    downloadReport
} from "@/api/modules/customReportViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        // receiveData: false,
        firstRequest: false,
        filters: [],
        filtersNotApplied: false,
        columns: [],
        reportData: [],
        totalLength: 0,
        changedFilers: {},
        context: {
            bNotFirstLoad: "0",
            bForceCRRefresh: "0"
        },
        parametersList: "",
        loadStatus: {
            loadAttempt: false,
            processLoad: false
        }
    },

    getters: {
        getFirstRequest(state) {
            return state.firstRequest;
        },
        // getLoadStatus(state) {
        //     return state.receiveData;
        // },
        getFilters(state) {
            return state.filters;
        },
        getFiltersNotApplied(state) {
            return state.filtersNotApplied;
        },
        getColumns(state) {
            return state.columns;
        },
        getTotalLength(state) {
            return state.totalLength;
        },
        getReportData(state) {
            return state.reportData;
        },
        getContext(state) {
            return state.context;
        },
        getParameters(state) {
            return state.parametersList;
        },
        getLoadStatus(state) {
            return state.loadStatus;
        }
    },

    mutations: {
        setFilters(state, filters) {
            if (!filters?.length) return;

            state.filters = filters;
        },
        changeParameters(state) {
            if (!state.filters?.length) return;

            for (let i = 0; i < state.filters.length; i += 1) {
                const currentFilter = state.filters[i];

                state.context[currentFilter.id] = "";
                state.parametersList += `${currentFilter.id},`;
            }
            state.parametersList = state.parametersList.replace(/,\s*$/, "");
        },
        setColumns(state, columns) {
            if (!columns.length) return;
            state.columns = columns;
            // state.receiveData = true;
        },
        setContext(state) {
            state.context.bNotFirstLoad = "1";
            state.context.bForceCRRefresh = "1";
        },
        setTotalLength(state, length) {
            state.totalLength = length;
        },
        setReportData(state, reportData) {
            state.firstRequest = true;
            if (!reportData) return;
            state.reportData = reportData;
        },
        changeFilters(state, { id, type, value }) {
            console.log(value)
            state.filtersNotApplied = true;
            state.changedFilers[id] = { type, value };
            state.context[id] = value;
        },
        resetFiltersNotApplied(state) {
            state.filtersNotApplied = false;
        },
        resetPageData(state) {
            state.loadStatus.loadAttempt = false;
            state.loadStatus.processLoad = false;
            // state.receiveData = false;
            state.firstRequest = false;
            state.filters = [];
            state.filtersNotApplied = false;
            state.columns = [];
            state.reportData = [];
            state.totalLength = 0;
            state.changedFilers = {};
            state.context = {
                bNotFirstLoad: "0",
                bForceCRRefresh: "0"
            };
            state.parametersList = "";
        },
        startLoad(state) {
            state.loadStatus.loadAttempt = true;
            state.loadStatus.processLoad = true;
        },
        finishLoad(state) {
            state.loadStatus.processLoad = false;
        }
    },

    actions: {
        async requestGetFilters(ctx, reportID) {
            getFilters(reportID)
                .then(response => {
                    if (
                        !response?.data ||
                        response.data?.error ||
                        !response.data?.filters
                    ) {
                        throw new Error(
                            response.data?.message ||
                                "Произошла ошибка при попытке получения фильтров"
                        );
                    }

                    if (!response.data.filters?.length) return;

                    let filtersToBeTreated = [];
                    for (let i = 0; i < response.data.filters.length; i += 1) {
                        const currentFilter = response.data.filters[i];

                        if (currentFilter?.id) {
                            filtersToBeTreated.push(currentFilter);
                        }
                    }

                    ctx.commit("setFilters", filtersToBeTreated);
                    ctx.commit("changeParameters");
                })
                .catch(error => console.log(error));
        },
        async requestGetCustomReportData(
            ctx,
            { id, context, params, pagination }
        ) {
            if (!context?.bNotFirstLoad) {
                return getCustomReportData({
                    id,
                    context: { bNotFirstLoad: "0", bForceCRRefresh: "0" },
                    params: "",
                    pagination
                })
                    .then(response => {
                        if (
                            !response?.data ||
                            response.data?.error ||
                            !response.data?.columns?.length
                        ) {
                            throw new Error(
                                response.data?.message ||
                                    "Произошла ошибка при попытке получения колонок настраиваемого отчёта"
                            );
                        }

                        ctx.commit("setColumns", response.data.columns);
                        ctx.commit("setContext", response.data.columns);
                    })
                    .catch(error => {
                        console.log(error);
                        // window.location.href=''
                    });
            }

            ctx.commit("startLoad");

            const parameters = {
                sReportMode: "table",
                custom_report_id: id,
                context,
                parameters_list: params
            };

            let encodeParameters = "";
            parameters.context = JSON.stringify(parameters.context);
            for (let param in parameters) {
                encodeParameters +=
                    param + "=" + encodeURIComponent(parameters[param]) + ";";
            }
            getCustomReportData({
                encodeParameters,
                id,
                context,
                params,
                pagination
            })
                .then(response => {
                    if (
                        !response?.data ||
                        response.data?.error ||
                        !response.data?.results
                    ) {
                        throw new Error(
                            response.data?.message ||
                                "Произошла ошибка при попытке получения данных настраиваемого отчёта"
                        );
                    }

                    ctx.commit("setTotalLength", response.data.total || 0);
                    ctx.commit("setReportData", response.data.results);
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    ctx.commit("finishLoad");
                });
        },
        async requestDownloadReport(ctx, { reportID, PDFMode }) {
            downloadReport(reportID, PDFMode).catch(error =>
                console.log(error)
            );
        }
    }
};
