import { getInfo, getCourses } from "@/api/modules/selfDevelopmentAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        courses: [],
        documents: []
    },

    getters: {
        getDocuments(state) {
            return state.documents.map(d => {
                return {
                    id: d.id,
                    icon: d.pict_url,
                    title: d.name,
                    routerLink: {
                        name: "self-development-content",
                        params: { id: d.id }
                    }
                };
            });
        },
        getCourses(state) {
            return state.courses.map(c => {
                return {
                    id: c.id,
                    title: c.name,
                    subTitle: "",
                    img: c.pict_url ? c.pict_url : "default-course-icon.jpg",
                    routerLink: {
                        name: "course",
                        params: {
                            id: c.id
                        }
                    }
                };
            });
        }
    },

    mutations: {
        setCourses(state, courses) {
            if (courses) {
                state.courses = courses;
            }
        },
        setDocuments(state, documents) {
            if (documents) {
                state.documents = documents;
            }
        }
    },

    actions: {
        async requestCourses(ctx, document_id) {
            const courses = await getCourses(document_id);

            if (courses && courses.data.error != 0) {
                throw new Error(courses.data.message);
            }

            ctx.commit("setCourses", courses.data.result);
        },

        async requestInfo(ctx) {
            try {
                const info = await getInfo();

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setDocuments", info.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
