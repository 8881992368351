import { getInfo } from "@/api/modules/completedCourseViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        courseInfo: {},
        completionRate: 0,
        modulesRate: {
            total: 0,
            completed: 0
        },
        courseMap: []
    },

    getters: {
        getCourseInfo(state) {
            return state.courseInfo;
        },
        getPercentage(state) {
            return state.completionRate;
        }
    },

    mutations: {
        setCourseInfo(state, courseInfo) {
            if (!state.courseInfo) return;
            state.courseInfo = courseInfo;
            state.completionRate =
                (state.courseInfo?.modules?.completed /
                    state.courseInfo?.modules?.max) *
                    100 || 0;
        }
    },

    actions: {
        async requestGetInfo(ctx, courseID) {
            try {
                let response = await getInfo(courseID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                ctx.commit("setCourseInfo", response.result);
            } catch (error) {
                console.log(error);
                // window.location.href = `${window.location.origin}/app/not-found`;
            }
        }
    }
};
