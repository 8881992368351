import request from "@/api/index";

const ENDPOINTS = {
    rgReportsAPI: "/custom_web_template.html?object_code=rg_reports_api",
    WTCustumReportsAPI: "/pp/Ext5/extjs_json_collection_data.html"
};

const ACTIONS = {
    getInfo: "get_info"
};

async function getReportsViewData(docId = "") {
    return request({
        url: ENDPOINTS.rgReportsAPI,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                document_id: docId
            })
        }
    });
}

async function getCustomReportData() {
    // parameters_list: "",
    // context: {
    //     bNotFirstLoad: "0",
    //     bForceCRRefresh: "0"
    // }
    const bodyFormData = new FormData();
    bodyFormData.append("collection_code", "CUSTOM_REPORT_DATA");
    bodyFormData.append(
        "parameters",
        "sReportMode=table;custom_report_id=6991791885532025933;context=%7B%22bNotFirstLoad%22%3A%221%22%2C%22bForceCRRefresh%22%3A%221%22%7D;parameters_list=;"
    );
    bodyFormData.append("start", "0");
    bodyFormData.append("limit", "100");
    // bodyFormData.append(
    //     "sort",
    //     JSON.stringify([
    //         {
    //             property: "",
    //             direction: ""
    //         }
    //     ])
    // );

    return request({
        url: ENDPOINTS.WTCustumReportsAPI,
        method: "POST",
        data: bodyFormData
    });
}

async function getReportFile(url) {
    return request({
        url,
        method: "GET",
        responseType: "blob"
    });
}

export { getReportsViewData, getReportFile, getCustomReportData };
