import { getUserfulViewData } from "@/api/modules/userfulViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        title: "",
        description: ""
    },

    getters: {
        getTitle(state) {
            return state.title;
        },
        getDescription(state) {
            return state.description;
        }
    },

    mutations: {
        setUserfulViewData(state, userfulViewData) {
            if (userfulViewData) {
                state.title = userfulViewData.name;
                state.description = userfulViewData.desc;
            }
        }
    },

    actions: {
        async requestUerfulPageData(ctx) {
            try {
                const userfulViewData = await getUserfulViewData();

                if (userfulViewData && userfulViewData.data.error != 0) {
                    throw new Error(userfulViewData.data.message);
                }

                ctx.commit("setUserfulViewData", userfulViewData.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
