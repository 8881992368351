import { replaceObjectKeyNamesByPattern } from "@/lib/object";

const OBJECT_KEYS = {
    id: "id",
    code: "code",
    name: "name",
    pict_url: "imgUrl",
    resource_id: "imgUrl",
    url: "url",
    type: "type",
    size: "size"
};

export const deserialize = json =>
    Array.isArray(json)
        ? json.map(item => replaceObjectKeyNamesByPattern(item, OBJECT_KEYS))
        : replaceObjectKeyNamesByPattern(json, OBJECT_KEYS);

export const deserializeObjects = json =>
    Object.entries(json).reduce(
        (map, [objectType, objects]) => ({
            ...map,
            [objectType]: deserialize(objects)
        }),
        {}
    );
