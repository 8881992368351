import { getInfo, changeComment } from "@/api/modules/eventResultAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        info: {}
    },

    getters: {},

    mutations: {
        setInfo(state, info) {
            if (info) {
                state.info = info;
            }
        }
    },

    actions: {
        async requestInfo(ctx, event_id) {
            try {
                const info = await getInfo(event_id);

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setInfo", info.data.result);
            } catch (error) {
                console.log(error);
            }
        },

        async requestChangeComment(ctx, payload) {
            await changeComment(payload.event_id, payload.comment).catch(
                error => console.log(error)
            );
        }
    }
};
