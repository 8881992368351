import userfulChildViewStore from "./subModules/userfulChildViewStore";
import pollsViewStore from "./subModules/pollsViewStore";
import contestsViewStore from "./subModules/contestsViewStore";

/* eslint-disable */
export default {
    namespaced: true,

    modules: {
        userfulChildViewStore,
        pollsViewStore,
        contestsViewStore
    }
};
