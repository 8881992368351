import {
    getInfo,
    getPlaces,
    getEducationOrgs,
    getRequestStates
} from "@/api/modules/eventsCalendarByProgramsAPI";

function parseDate(date) {
    const arrDate = date.split(" ");
    return new Date(
        `${arrDate[0].split(".").reverse().join("-")}T${arrDate[1]}`
    );
}

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        places: [],
        educationOrgs: [],
        events: [],
        requestStates: []
    },

    getters: {
        getPlaces(state) {
            let places = [];

            places = state.places.map(p => {
                return {
                    text: p.name,
                    value: p.id
                };
            });

            places.unshift({
                text: "Все",
                value: ""
            });

            return places;
        },
        getEducationOrgs(state) {
            let educationOrgs = [];

            educationOrgs = state.educationOrgs.map(p => {
                return {
                    text: p.name,
                    value: p.id
                };
            });

            educationOrgs.unshift({
                text: "Все",
                value: ""
            });

            return educationOrgs;
        },
        getEvents(state) {
            return state.events;
        },
        getRequestStates(state) {
            return state.requestStates;
        }
    },

    mutations: {
        setPlaces(state, places) {
            if (places) {
                state.places = places;
            }
        },
        setEducationOrgs(state, educationOrgs) {
            if (educationOrgs) {
                state.educationOrgs = educationOrgs;
            }
        },
        setEvents(state, events) {
            if (events) {
                state.events = [];
                events.forEach(e => {
                    let date;
                    let index_date = parseDate(e.start_date);
                    let finish_date = parseDate(e.finish_date);

                    while (index_date <= finish_date) {
                        date = index_date
                            .toLocaleDateString()
                            .split(".");

                        state.events.push({
                            id: e.id,
                            code: e.code,
                            title: e.name,
                            start_date: e.start_date,
                            finish_date: e.finish_date,
                            status: e.status,
                            type: e.type,
                            date: `${Number(date[2])}-${date[1]}-${date[0]}`,
                            month: Number(date[1]),
                            year: Number(date[2]),
                            url: "/app/event/" + e.id
                        });

                        index_date.setDate(index_date.getDate() + 1);
                    }
                });
            }
        },
        setRequestStates(state, requestStates) {
            if (requestStates) {
                state.requestStates = requestStates;
            }
        }
    },

    actions: {
        async requestPlaces(ctx) {
            const places = await getPlaces();

            if (places && places.data.error != 0) {
                throw new Error(places.data.message);
            }

            ctx.commit("setPlaces", places.data.result);
        },

        async requestEducationOrgs(ctx) {
            const educationOrgs = await getEducationOrgs();

            if (educationOrgs && educationOrgs.data.error != 0) {
                throw new Error(educationOrgs.data.message);
            }

            ctx.commit("setEducationOrgs", educationOrgs.data.result);
        },

        async requestRequestStates(ctx) {
            const requestStates = await getRequestStates();

            if (requestStates && requestStates.data.error != 0) {
                throw new Error(requestStates.data.message);
            }

            ctx.commit("setRequestStates", requestStates.data.result);
        },

        async requestInfo(ctx, params) {
            try {
                const info = await getInfo(
                    params.start_date,
                    params.finish_date,
                    params.place_id,
                    params.education_org_id,
                    params.workflow_state
                );

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setEvents", info.data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
