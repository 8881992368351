import {
    checkAccess,
    getInfo,
    activateCourse
} from "@/api/modules/courseViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        courseInfo: {},
        courseMap: []
    },

    getters: {
        getCourseInfo(state) {
            return state.courseInfo;
        }
    },

    mutations: {
        setCourseInfo(state, courseInfo) {
            state.courseInfo = courseInfo;
        }
    },

    actions: {
        async requestCheckAccess(ctx, courseID) {
            try {
                let response = await checkAccess(courseID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                return response.result;
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        async requestGetInfo(ctx, courseID) {
            try {
                let response = await getInfo(courseID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                ctx.commit("setCourseInfo", response.result);
            } catch (error) {
                console.log(error);
                window.location.href = `${window.location.origin}/app/not-found`;
            }
        },
        async requestActivateCourse(ctx, courseID) {
            try {
                let response = await activateCourse(courseID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                return response.result;
            } catch (error) {
                console.log(error);
                // window.location.href = `${window.location.origin}/app/not-found`;
            }
        }
    }
};
