import request from "@/api/index";
import { checkForDocRedirect } from "@/lib/api-middlewares";

// const ENDPOINTS = {
//     "self-development":
//         "/custom_web_template.html?object_code=rg_self_education_api",
//     "trade-marks": "/custom_web_template.html?object_code=rg_trade_marks_api",
//     "video-fund": "/custom_web_template.html?object_code=rg_tutorial_api",
//     "service-standards":
//         "/custom_web_template.html?object_code=rg_service_standards_api",
//     "store-management":
//         "/custom_web_template.html?object_code=rg_store_management_api"
// };
const ENDPOINT = "/custom_web_template.html?object_code=rg_knowledge_base_api";
const ACTIONS = {
    getInfo: "get_info"
};

async function getInfo(sectionID = "") {
    return checkForDocRedirect(
        await request({
            url: ENDPOINT,
            method: "POST",
            params: {
                action: ACTIONS.getInfo,
                parameters: JSON.stringify({
                    id: sectionID
                })
            }
        })
    );
}

export { getInfo };
