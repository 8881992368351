import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_course_api";
const ACTIONS = {
    checkAccess: "check_access",
    getInfo: "get_info",
    activateCourse: "activate_course"
};

async function checkAccess(courseID, result = false) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.checkAccess,
            parameters: JSON.stringify({
                result,
                course_id: courseID
            })
        }
    });
}

async function getInfo(courseID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                course_id: courseID
            })
        }
    });
}

async function activateCourse(courseID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.activateCourse,
            parameters: JSON.stringify({
                course_id: courseID
            })
        }
    });
}

export { checkAccess, getInfo, activateCourse };
