const APP_LAYOUT_TO_FILE_MAP = {
    default: "DefaultLayout.vue",
    VoidLayout: "VoidLayout.vue"
};

/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
async function loadLayoutMiddleware(route) {
    const { layout } = route.meta;
    const normalizedLayoutName = layout || "default";
    const fileName = APP_LAYOUT_TO_FILE_MAP[normalizedLayoutName];
    const fileNameWithoutExtension = fileName.split(".vue")[0];
    const component = await import(
        `../../layouts/${fileNameWithoutExtension}.vue`
    );
    route.meta.layoutComponent = component.default;
}

export { loadLayoutMiddleware };
