import { login, passwordReminder } from "@/api/modules/authViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    actions: {
        async requestLogin(ctx, { username, password }) {
            try {
                const authorizationResult = await login({ username, password });

                if (!authorizationResult.data) {
                    return {
                        error: 1
                    };
                }

                return authorizationResult.data;
            } catch (error) {
                console.log(error);
            }
        },

        async requestPasswordReminder(ctx, recoveryData) {
            try {
                await passwordReminder(recoveryData);
            } catch (error) {
                return;
            }
        }
    }
};
