import { getContestsViewData } from "@/api/modules/userfulViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        contests: [],
        currentSectionID: "",
        currentPath: [],
        currentHeader: "",
        currentDescription: "",
        subSectionName: ""
    },

    getters: {
        getContests(state) {
            if (state.subSectionName) {
                return state.contests.filter(section => {
                    return section.title
                        .toLowerCase()
                        .includes(state.subSectionName.toLowerCase());
                });
            }
            return state.contests;
        },
        getCurrentSectionID(state) {
            return state.currentSectionID;
        },
        getCurrentPath(state) {
            return state.currentPath;
        },
        getCurrentHeader(state) {
            return state.currentHeader;
        },
        getCurrentDescription(state) {
            return state.currentDescription;
        }
    },

    mutations: {
        setSectionParameters(state, result) {
            state.currentSectionID = result.id || "";
            state.currentHeader = result.name || "";
            state.currentDescription = result.desc || "";
        },
        setContestsViewData(state, contestsViewData) {
            state.currentPath = [
                { name: "Главная", url: "/app" },
                { name: "Полезное", url: "/app/userful" },
                {
                    name: "Конкурсы"
                }
            ];

            if (contestsViewData?.sections) {
                let normalizeContests = [];
                for (let i = 0; i < contestsViewData.sections.length; i++) {
                    const currentContest = contestsViewData.sections[i];
                    const normalizeContest = {
                        id: currentContest.id || "",
                        title: currentContest.name || "",
                        description: currentContest.desc || "",
                        icon: currentContest.pict_url || ""
                    };
                    normalizeContests.push(normalizeContest);
                }
                state.contests = normalizeContests;
            }
        },
        setSubContestsViewData(state, contestsViewData) {
            if (contestsViewData?.sections) {
                state.currentPath = [
                    { name: "Главная", url: "/app" },
                    { name: "Полезное", url: "/app/userful" },
                    {
                        name: "Конкурсы",
                        url: "/app/userful/contests"
                    }
                ];

                for (
                    let i = 0;
                    i < contestsViewData.bread_crumbs?.length;
                    i += 1
                ) {
                    const currentBreadCrumb = contestsViewData.bread_crumbs[i];

                    state.currentPath.push({
                        name: currentBreadCrumb?.name,
                        url: `${state.currentPath[2 + i]?.url}/${
                            currentBreadCrumb?.id
                        }`
                    });
                }

                let normalizeContests = [];
                for (let i = 0; i < contestsViewData.sections.length; i++) {
                    const currentContest = contestsViewData.sections[i];
                    const normalizeContest = {
                        id: currentContest.id || "",
                        title: currentContest.name || "",
                        description: currentContest.desc || "",
                        icon: currentContest.pict_url || ""
                    };
                    normalizeContests.push(normalizeContest);
                }
                state.contests = normalizeContests;
            }
        },
        setSubSectionName(state, name) {
            state.subSectionName = name;
        }
    },

    actions: {
        async requestContestsPageData(ctx, docID = "") {
            try {
                const contestsViewData = await getContestsViewData(docID);

                if (contestsViewData && contestsViewData.data.error != 0) {
                    throw new Error(contestsViewData.data.message);
                }

                ctx.commit(
                    "setSectionParameters",
                    contestsViewData.data.result
                );

                if (docID) {
                    ctx.commit(
                        "setSubContestsViewData",
                        contestsViewData.data.result
                    );
                } else {
                    ctx.commit(
                        "setContestsViewData",
                        contestsViewData.data.result
                    );
                }

            } catch (error) {
                console.log(error);
            }
        }
    }
};
