import request from "@/api/index";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_event_api";
const ACTIONS = {
    getInfo: "get_info",
    getPlaces: "get_places",
    getEducationOrgs: "get_education_orgs",
    getRequestStates: "get_request_states"
};

async function getInfo(
    start_date,
    finish_date,
    place_id = "",
    education_org_id = "",
    workflow_state = ""
) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                start_date: start_date,
                finish_date: finish_date,
                place_id: place_id,
                education_org_id: education_org_id,
                workflow_state: workflow_state
            })
        }
    });
}

async function getPlaces() {
    return request({
        url: ENDPOINT,
        method: "GET",
        params: {
            action: ACTIONS.getPlaces
        }
    });
}

async function getEducationOrgs() {
    return request({
        url: ENDPOINT,
        method: "GET",
        params: {
            action: ACTIONS.getEducationOrgs
        }
    });
}

async function getRequestStates() {
    return request({
        url: ENDPOINT,
        method: "GET",
        params: {
            action: ACTIONS.getRequestStates
        }
    });
}

export { getInfo, getPlaces, getEducationOrgs, getRequestStates };
