import request from "@/api/index";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_event_page_api";
const ACTIONS = {
    getInfo: "get_info",
    addPersonToEvent: "add_person_to_event",
    changeStatus: "change_status",
    changeComment: "change_comment",
    createRequest: "create_request",
    deletePersonToEvent: "delete_person_to_event",
    changeEventStatus: "change_event_status",
    changePatricipantStatus: "change_patricipant_status"
};

async function getInfo(eventID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                id: eventID
            })
        }
    });
}

async function addPersonToEvent(eventID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.addPersonToEvent,
            parameters: JSON.stringify({
                id: eventID
            })
        }
    });
}

async function changeStatus(eventID, value) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.changeStatus,
            parameters: JSON.stringify({
                id: eventID,
                value: value
            })
        }
    });
}

async function changeComment(eventID, comment) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.changeComment,
            parameters: JSON.stringify({
                id: eventID,
                comment: comment
            })
        }
    });
}

async function deletePersonToEvent(eventID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.deletePersonToEvent,
            parameters: JSON.stringify({
                id: eventID
            })
        }
    });
}

async function changeEventStatus(eventID, status){
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.changeEventStatus,
            parameters: JSON.stringify({
                id: eventID,
                status: status
            })
        }
    })
}

async function changePatricipantStatus(eventID, personID, field, value){
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.changePatricipantStatus,
            parameters: JSON.stringify({
                id: eventID,
                person_id: personID,
                field: field,
                value: value
            })
        }
    })
}

export { getInfo, addPersonToEvent, changeStatus, changeComment, deletePersonToEvent, changeEventStatus, changePatricipantStatus };
