import request from "@/api/index";

const ENDPOINTS = {
    polls: "/custom_web_template.html?object_code=rg_polls_api",
    contests: "/custom_web_template.html?object_code=rg_contests_api",
    userful: "/custom_web_template.html?object_code=rg_useful_api"
};
const ACTIONS = {
    getInfo: "get_info",
    docId: "document_id"
};

async function getContestsViewData(docID = "") {
    return request({
        url: ENDPOINTS.contests,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                document_id: docID
            })
        }
    });
}

async function getPollsViewData() {
    return request({
        url: ENDPOINTS.polls,
        method: "GET",
        params: {
            action: ACTIONS.getInfo
        }
    });
}

async function getUserfulViewData() {
    return request({
        url: ENDPOINTS.userful,
        method: "POST",
        params: {
            action: ACTIONS.getInfo
        }
    });
}

export { getContestsViewData, getPollsViewData, getUserfulViewData };
