import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_active_course_api";
const ACTIONS = {
    getInfo: "get_info",
    finishCourse: "finish_course"
};

async function getInfo(courseID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                id: courseID
            })
        }
    });
}

export { getInfo };
