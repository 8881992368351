import axios from "axios";

const NO_AUTHORIZARION_RES_DATA = 'document.location.href = "/default.html"';

axios.interceptors.response.use(
    response => {
        if (typeof response.data === "string") {
            if (response.data.includes(NO_AUTHORIZARION_RES_DATA)) {
                window.location.href = `${window.location.origin}/${process.env.VUE_APP_PATH_TO_LOGIN}`;
                return Promise.reject(new Error("no authorization error"));
            }
        }
        if (response.data?.error) {
            alert(response.data?.message);
        }
        return response;
    },
    error => {
        if (error.response?.status === 401) {
            window.location.href = "/app/auth";
        }
        return Promise.reject(error);
    }
);

export default ({
    url = "/",
    method = "get",
    headers = {},
    params = {},
    data = {},
    responseType = ""
}) =>
    axios({
        url,
        method,
        headers,
        params,
        data,
        responseType
    });
