import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_active_test_api";
const ACTIONS = {
    getInfo: "get_info",
    finishAttempt: "finish_test_attempt",
    finishTest: "finish_test"
};

async function getInfo(testID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                id: testID
            })
        }
    });
}

async function finishAttempt(testID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.finishAttempt,
            parameters: JSON.stringify({
                id: testID
            })
        }
    });
}

async function finishTest(testID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.finishTest,
            parameters: JSON.stringify({
                id: testID
            })
        }
    });
}

export { getInfo, finishAttempt, finishTest };
