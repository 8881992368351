import {
    getInfo,
    getLibraryMaterials,
    getDocumentObjects
} from "@/api/modules/videoFundAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        // libraryMaterials: [],
        documents: [],
        objectsMap: {},
        objects: {},
        objectsDisabled: true,
        nameFilterValue: "",
        desc: ""
    },

    getters: {
        getDocuments(state) {
            return state.documents.map(d => {
                return {
                    id: d.id,
                    desc: d.desc,
                    icon: d.pict_url,
                    title: d.name,
                    routerLink: {
                        name: "video-fund-content",
                        params: { id: d.id }
                    }
                };
            });
        },
        // getLibraryMaterials(state) {
        //     return state.libraryMaterials.map(m => {
        //         return {
        //             id: m.id,
        //             title: m.name,
        //             subTitle: "",
        //             img: m.pict_url ? m.pict_url : "default-video-icon.jpg",
        //             url: "/_wt/library_material/" + m.id
        //         };
        //     });
        // },
        getObjects(state) {
            let filteredObjects = state.objects;

            if (state.nameFilterValue) {
                filteredObjects = {};
                for (let objectType in state.objects) {
                    const currentObjectItems = state.objects[objectType];
                    filteredObjects[objectType] = currentObjectItems.filter(
                        object => {
                            return object?.title
                                ?.trim()
                                ?.toLowerCase()
                                .includes(
                                    state.nameFilterValue.trim().toLowerCase()
                                );
                        }
                    );
                }
            }

            return filteredObjects;
        },
        getObjectsMap(state) {
            return state.objectsMap;
        },
        getObjectsDisabled(state) {
            return state.objectsDisabled;
        },
        getDesc(state) {
            return state.desc;
        }
    },

    mutations: {
        // setLibraryMaterials(state, libraryMaterials) {
        //     if (libraryMaterials) {
        //         state.libraryMaterials = libraryMaterials;
        //     }
        // },
        resetPageData(state) {
            state.documents = [];
            state.objects = {};
            state.objectsMap = {};
            state.objectsDisabled = true;
            state.nameFilterValue = "";
        },
        setNameFilterValue(state, filterValue) {
            state.nameFilterValue = filterValue;
        },
        setDocumentObjects(state, documentObjects) {
            if (documentObjects) {
                const normalizedObjects = {};

                for (let objectType in documentObjects) {
                    const currentObjectItems = documentObjects[objectType];
                    const normalizedItems = currentObjectItems.map(item => {
                        state.objectsDisabled = false;
                        let currentRouterLink = {};

                        if (objectType === "assessment") {
                            currentRouterLink = {
                                name: "test",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "course") {
                            currentRouterLink = {
                                name: "course",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "custom_report") {
                            currentRouterLink = {
                                name: "custom-report",
                                params: {
                                    id: item?.id,
                                    name: item?.name
                                }
                            };
                        } else if (objectType === "education_method") {
                            currentRouterLink = {
                                name: "education-method",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "event") {
                            currentRouterLink = {
                                name: "event",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "poll") {
                            currentRouterLink = {
                                name: "poll",
                                params: {
                                    id: item?.id
                                }
                            };
                        } else if (objectType === "video") {
                            currentRouterLink.url = item?.link_href;
                        } else {
                            currentRouterLink.url = `${window.location.origin}/_wt/${item?.id}`;
                        }

                        if (currentRouterLink?.url) {
                            return {
                                id: item.id,
                                img: item.pict_url ? item.pict_url : "",
                                title: item.name,
                                url: currentRouterLink.url
                            };
                        }

                        return {
                            id: item.id,
                            img: item.pict_url ? item.pict_url : "",
                            title: item.name,
                            routerLink: currentRouterLink
                        };
                    });

                    normalizedObjects[objectType] = normalizedItems;
                }

                state.objects = normalizedObjects;

                for (let objectType in state.objects) {
                    if (objectType === "assessment") {
                        state.objectsMap[objectType] = "Тесты";
                        continue;
                    }
                    if (objectType === "compound_program") {
                        state.objectsMap["compound_program"] =
                            "Модульная программа";
                        continue;
                    }
                    if (objectType === "course") {
                        state.objectsMap[objectType] = "Электронные курсы";
                        continue;
                    }
                    if (objectType === "custom_report") {
                        state.objectsMap[objectType] = "Настраиваемые отчеты";
                        continue;
                    }
                    if (objectType === "education_method") {
                        state.objectsMap[objectType] = "Учебные программы";
                        continue;
                    }
                    if (objectType === "education_program") {
                        state.objectsMap[objectType] = "Наборы программ";
                        continue;
                    }
                    if (objectType === "event") {
                        state.objectsMap[objectType] = "Мероприятия";
                        continue;
                    }
                    if (objectType === "library_material") {
                        state.objectsMap[objectType] = "Материалы библиотеки";
                        continue;
                    }
                    if (objectType === "poll") {
                        state.objectsMap[objectType] = "Опросы";
                        continue;
                    }
                    if (objectType === "resource") {
                        state.objectsMap[objectType] = "Ресурсы базы";
                        continue;
                    }
                    if (objectType === "video") {
                        state.objectsMap[objectType] = "Видео";
                        continue;
                    }
                }
            }
        },
        setDocuments(state, documents) {
            if (documents) {
                state.documents = documents;
            }
        },
        setDesc(state, desc) {
            if (desc) {
                state.desc = desc;
            }
        }
    },

    actions: {
        // async requestLibraryMaterials(ctx, document_id) {
        //     const libraryMaterials = await getLibraryMaterials(document_id);

        //     if (libraryMaterials && libraryMaterials.data.error != 0) {
        //         throw new Error(libraryMaterials.data.message);
        //     }

        //     ctx.commit("setLibraryMaterials", libraryMaterials.data.result);
        // },

        async requestDocumentObjects(ctx, document_id = "") {
            const documentObjects = await getDocumentObjects(document_id);

            if (documentObjects && documentObjects.data.error != 0) {
                throw new Error(documentObjects.data.message);
            }

            ctx.commit("setDocumentObjects", documentObjects.data.result);
        },

        async requestInfo(ctx) {
            try {
                const info = await getInfo();

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setDocuments", info.data.result.documents);
                ctx.commit("setDesc", info.data.result.desc);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
