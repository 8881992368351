import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_poll_page_api";
const ACTIONS = {
    getInfo: "get_info"
};

async function getInfo(pollID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                id: pollID
            })
        }
    });
}

export { getInfo };
