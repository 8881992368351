import {
    getMyRequestsViewData,
    getModalRequestTypesData
} from "@/api/modules/myRequestsAPI";

function validateDate(date) {
    const arrDate = date.split(" ");
    return new Date(
        `${arrDate[0].split(".").reverse().join("-")}T${arrDate[1]}`
    );
}

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        requests: { data: [], sort: "" },
        modalRequestTypesData: [],
        activeTypeIDFilter: false,
        selectOptions: [{ text: "Все", value: "" }],
        requestFilters: {}
    },

    getters: {
        getRequests(state) {
            if (
                state.requests.data.length &&
                Object.keys(state.requestFilters).length
            ) {
                let filteredRequests = state.requests.data;

                if (state.requestFilters?.status) {
                    filteredRequests = filteredRequests.filter(request => {
                        return request.status === state.requestFilters.status;
                    });
                }

                if (
                    filteredRequests.length &&
                    state.requestFilters?.requestTypeName
                ) {
                    filteredRequests = filteredRequests.filter(request => {
                        return request.type
                            .toLowerCase()
                            .includes(
                                state.requestFilters.requestTypeName.toLowerCase()
                            );
                    });
                }

                return filteredRequests;
            }

            return state.requests.data;
        },
        getModalRequestTypesData(state) {
            return state.modalRequestTypesData;
        },
        getActiveTypeIDFilter(state) {
            return state.activeTypeIDFilter;
        },
        getRequestsSortMod(state) {
            return state.requests.sort;
        },
        getSelectOptions(state) {
            return state.selectOptions;
        }
    },

    mutations: {
        setMyRequestsViewData(state, { data, typeID }) {
            if (data) {
                state.activeTypeIDFilter = typeID ? true : false;
                state.requests.data = data;
            }
        },
        setModalRequestTypesData(state, modalRequestTypesData) {
            if (modalRequestTypesData) {
                state.modalRequestTypesData = modalRequestTypesData;
            }
        },
        setSelectOptions(state) {
            if (state.requests.data.length) {
                let selectOptions = [{ text: "Все", value: "" }];
                const statuses = state.requests.data.reduce(
                    (accumulator, current) => {
                        return [...accumulator, current.status];
                    },
                    []
                );
                const unicStatus = new Set(statuses);
                for (let item of unicStatus.values()) {
                    selectOptions.push({
                        text: item,
                        value: item
                    });
                }
                state.selectOptions = selectOptions;
            }
        },
        setRequestFilter(state, values = {}) {
            state.requestFilters = values;
        },
        filterRequestsByCreateDate(state) {
            if (state.requests.data.length) {
                if (state.requests.sort === "createDate") {
                    state.requests.data.reverse();
                    state.requests.sort = "createDateReverse";
                } else {
                    state.requests.data.sort((a, b) => {
                        const dateEndA = validateDate(a.create_date).valueOf(),
                            dateEndB = validateDate(b.create_date).valueOf();
                        return dateEndA - dateEndB;
                    });
                    state.requests.sort = "createDate";
                }
            }
        },
        filterRequestsByCode(state) {
            if (state.requests.data.length) {
                if (state.requests.sort === "code") {
                    state.requests.data.reverse();
                    state.requests.sort = "codeReverse";
                } else {
                    state.requests.data.sort((a, b) => {
                        const codeA = a.code.toLowerCase(),
                            codeB = b.code.toLowerCase();
                        if (codeA < codeB) return -1;
                        if (codeA > codeB) return 1;
                        return 0;
                    });
                    state.requests.sort = "code";
                }
            }
        },
        filterRequestsByType(state) {
            if (state.requests.data.length) {
                if (state.requests.sort === "type") {
                    state.requests.data.reverse();
                    state.requests.sort = "typeReverse";
                } else {
                    state.requests.data.sort((a, b) => {
                        const typeA = a.type.toLowerCase(),
                            typeB = b.type.toLowerCase();
                        if (typeA < typeB) return -1;
                        if (typeA > typeB) return 1;
                        return 0;
                    });
                    state.requests.sort = "type";
                }
            }
        },
        filterRequestsByObjectType(state) {
            if (state.requests.data.length) {
                if (state.requests.sort === "objectType") {
                    state.requests.data.reverse();
                    state.requests.sort = "objectTypeReverse";
                } else {
                    state.requests.data.sort((a, b) => {
                        const objectTypeA = a.object_type.toLowerCase(),
                            objectTypeB = b.object_type.toLowerCase();
                        if (objectTypeA < objectTypeB) return -1;
                        if (objectTypeA > objectTypeB) return 1;
                        return 0;
                    });
                    state.requests.sort = "objectType";
                }
            }
        },
        filterRequestsByObjectName(state) {
            if (state.requests.data.length) {
                if (state.requests.sort === "objectName") {
                    state.requests.data.reverse();
                    state.requests.sort = "objectNameReverse";
                } else {
                    state.requests.data.sort((a, b) => {
                        const objectNameA = a.object_name.toLowerCase(),
                            objectNameB = b.object_name.toLowerCase();
                        if (objectNameA < objectNameB) return -1;
                        if (objectNameA > objectNameB) return 1;
                        return 0;
                    });
                    state.requests.sort = "objectName";
                }
            }
        },
        filterRequestsByStatus(state) {
            if (state.requests.data.length) {
                if (state.requests.sort === "status") {
                    state.requests.data.reverse();
                    state.requests.sort = "statusReverse";
                } else {
                    state.requests.data.sort((a, b) => {
                        const statusA = a.status.toLowerCase(),
                            statusB = b.status.toLowerCase();
                        if (statusA < statusB) return -1;
                        if (statusA > statusB) return 1;
                        return 0;
                    });
                    state.requests.sort = "status";
                }
            }
        }
    },

    actions: {
        async requestMyRequestsViewData(ctx, typeID = "") {
            try {
                const myRequestsViewData = await getMyRequestsViewData(typeID);

                if (myRequestsViewData && myRequestsViewData.data.error != 0) {
                    throw new Error(myRequestsViewData.data.message);
                }

                ctx.commit("setMyRequestsViewData", {
                    data: myRequestsViewData.data.result,
                    typeID
                });
                ctx.commit("filterRequestsByCreateDate");
                ctx.commit("setSelectOptions");
            } catch (error) {
                console.log(error);
            }
        },
        async requestModalRequestTypesData(ctx) {
            const modalRequestTypesData = await getModalRequestTypesData();

            if (
                modalRequestTypesData &&
                modalRequestTypesData.data.error != 0
            ) {
                throw new Error(modalRequestTypesData.data.message);
            }

            ctx.commit(
                "setModalRequestTypesData",
                modalRequestTypesData.data.result
            );
        }
    }
};
