import request from "@/api/index";
import { checkForDocRedirect } from "@/lib/api-middlewares";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_self_education_api";
const ACTIONS = {
    getInfo: "get_info",
    getCourses: "get_courses"
};

async function getInfo() {
    return checkForDocRedirect(
        await request({
            url: ENDPOINT,
            method: "GET",
            params: {
                action: ACTIONS.getInfo
            }
        })
    );
}

async function getCourses(document_id) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getCourses,
            parameters: JSON.stringify({
                document_id: document_id
            })
        }
    });
}

export { getInfo, getCourses };
