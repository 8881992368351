import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_request_api";
const ACTIONS = {
    getInfo: "get_info",
    getTypesList: "get_type_list"
};

async function getMyRequestsViewData(typeID = "") {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                type_id: typeID
            })
        }
    });
}

async function getModalRequestTypesData() {
    return request({
        url: ENDPOINT,
        method: "GET",
        params: {
            action: ACTIONS.getTypesList
        }
    });
}

export { getMyRequestsViewData, getModalRequestTypesData };
