import request from "@/api/index";

/* eslint-disable */
const ENDPOINT = "/custom_web_template.html?object_code=rg_event_result_api";
const ACTIONS = {
    getInfo: "get_info",
    changeComment: "change_comment"
};

async function getInfo(eventID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                id: eventID
            })
        }
    });
}

async function changeComment(eventID, comment) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.changeComment,
            parameters: JSON.stringify({
                id: eventID,
                comment: comment
            })
        }
    });
}

export { getInfo, changeComment };
