import request from "@/api/index";

const ENDPOINTS = {
    auth: "/custom_web_template.html?object_code=rg_login_api",
    reminder: "/password_reminder.html"
};

async function login({ username, password }) {
    const bodyFormData = new FormData();
    bodyFormData.append("set_auth", 1);
    bodyFormData.append("user_login", username);
    bodyFormData.append("user_password", password);

    return request({
        url: ENDPOINTS.auth,
        method: "POST",
        data: bodyFormData
    });
}

async function passwordReminder(recoveryData) {
    const bodyFormData = new FormData();
    bodyFormData.append("mode", "");
    bodyFormData.append("login", recoveryData);
    bodyFormData.append("submit", "Напомнить");

    return request({
        url: ENDPOINTS.reminder,
        method: "POST",
        data: bodyFormData
    });
}

export { login, passwordReminder };
