import { INIT } from "./action_types";
import { SET_CURRENT_BREAKPOINT } from "./mutation_types";

export default {
    [INIT]({ commit }) {
        const resizeHandler = () => {
            commit(SET_CURRENT_BREAKPOINT, getBreakpoint());
        };

        const getBreakpoint = () => {
            return window
                .getComputedStyle(document.body, ":before")
                .content.replace(/"/g, "");
        };

        resizeHandler();
        window.addEventListener("resize", resizeHandler);
    }
};
