import getCompletedCoursesViewData from "@/api/modules/completedCoursesViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        completedCourses: [],
        maxPage: 1
    },

    getters: {
        getCompletedCourses(state) {
            return state.completedCourses;
        },
        getMaxPage(state) {
            return state.maxPage;
        }
    },

    mutations: {
        setCompletedCoursesViewData(state, completedCoursesViewData) {
            if (completedCoursesViewData) {
                state.completedCourses = completedCoursesViewData;
                if (completedCoursesViewData[0]?.pages) {
                    state.maxPage = completedCoursesViewData[0].pages;
                }
            }
        }
    },

    actions: {
        async requestCompletedCoursesPageData(
            ctx,
            options = { status: "", search: "", page: 0 }
        ) {
            try {
                const completedCoursesViewData = await getCompletedCoursesViewData(
                    options
                );

                if (
                    completedCoursesViewData &&
                    completedCoursesViewData.data.error != 0
                ) {
                    throw new Error(completedCoursesViewData.data.message);
                }

                ctx.commit(
                    "setCompletedCoursesViewData",
                    completedCoursesViewData.data.result
                );
            } catch (error) {
                console.log(error);
            }
        }
    }
};
