import { getInfo } from "@/api/modules/serviceStandardsAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        courses: [],
        materials: []
    },

    getters: {
        getCourses(state) {
            return state.courses.map(c => {
                return {
                    id: c.id,
                    img: c.pict_url ? c.pict_url : "preview-image.jpg",
                    title: c.name,
                    routerLink: {
                        name: "course",
                        params: {
                            id: c.id
                        }
                    }
                };
            });
        },
        getMaterials(state) {
            return state.materials.map(m => {
                return {
                    id: m.id,
                    img: m.pict_url ? m.pict_url : "default-material-icon.jpg",
                    title: m.name,
                    url: "/_wt/library_material/" + m.id
                };
            });
        }
    },

    mutations: {
        setCourses(state, courses) {
            if (courses) {
                state.courses = courses;
            }
        },
        setMaterials(state, materials) {
            if (materials) {
                state.materials = materials;
            }
        }
    },

    actions: {
        async requestInfo(ctx) {
            try {
                const info = await getInfo();

                if (info && info.data.error != 0) {
                    throw new Error(info.data.message);
                }

                ctx.commit("setCourses", info.data.result.courses);
                ctx.commit("setMaterials", info.data.result.materials);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
