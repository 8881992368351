import { getActivities } from "@/api/modules/activities";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        activities: [],
        maxPage: 1
    },

    mutations: {
        setActivities(state, payload) {
            if (payload) {
                state.activities = payload;

                state.activities.forEach(a => a.finish_date = a.last_usage_date);

                if (payload[0]?.pages) {
                    state.maxPage = payload[0].pages;
                }
            }
        }
    },

    actions: {
        async loadActivities(
            ctx,
            options = { status: "", search: "", page: 0 }
        ) {
            try {
                const { data } = await getActivities({
                    isArchive: true,
                    ...options
                });

                if (data.error) {
                    throw new Error(data.message);
                }

                ctx.commit("setActivities", data.result);
            } catch (error) {
                console.log(error);
            }
        }
    }
};
