import MUTATION_TYPES from "./mutation-types";
import initialState from "./state";

export default {
    [MUTATION_TYPES.SET_LOADING_STATE](state, payload) {
        state.status.loading = { ...state.status.loading, ...payload };
    },
    [MUTATION_TYPES.SET_DATA](state, { key, value }) {
        state[key] = value;
    },
    [MUTATION_TYPES.SET_OBJECTS](state, payload) {
        state.objects = { ...state.objects, ...payload };
    },
    [MUTATION_TYPES.RESET](state) {
        Object.assign(state, initialState());
    }
};
