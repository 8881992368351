export const replaceObjectKeyNamesByPattern = (obj, patternObj) => {
    return Object.fromEntries(
        Object.entries(obj).reduce((acc, curr) => {
            const isArrayMode = Array.isArray(patternObj[curr[0]]);

            const key = isArrayMode
                ? patternObj[curr[0]][0]
                : patternObj[curr[0]];
            const value = isArrayMode ? patternObj[curr[0]][1] : curr[1];

            return key ? [...acc, [key, value]] : acc;
        }, [])
    );
};
