import request from "@/api/index";

const ENDPOINT = "/custom_web_template.html?object_code=rg_test_api";
const ACTIONS = {
    checkAccess: "check_access",
    getInfo: "get_info",
    activateTest: "activation_education"
};

async function checkAccess(testID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.checkAccess,
            parameters: JSON.stringify({
                id: testID
            })
        }
    });
}

async function getInfo(testID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.getInfo,
            parameters: JSON.stringify({
                id: testID
            })
        }
    });
}

async function activateTest(testID) {
    return request({
        url: ENDPOINT,
        method: "POST",
        params: {
            action: ACTIONS.activateTest,
            parameters: JSON.stringify({
                id: testID
            })
        }
    });
}

export { checkAccess, getInfo, activateTest };
