import { getInfo } from "@/api/modules/pollViewAPI";

/* eslint-disable */
export default {
    namespaced: true,

    state: {
        pollInfo: {}
    },

    getters: {
        getPollInfo(state) {
            return state.pollInfo;
        }
    },

    mutations: {
        setPollInfo(state, pollInfo) {
            state.pollInfo = pollInfo;
        }
    },

    actions: {
        async requestGetInfo(ctx, pollID) {
            try {
                let response = await getInfo(pollID);
                response = response?.data;

                if (!response || response?.error || !response?.result) {
                    throw new Error(response?.message);
                }

                ctx.commit("setPollInfo", response.result);
            } catch (error) {
                console.log(error);
                window.location.href = `${window.location.origin}/app/not-found`;
            }
        }
    }
};
